import React from "react"
import {Alert} from "@instructure/ui-alerts";
import {Flex} from "@instructure/ui-flex";
import PropTypes from "prop-types";

/**
 * Displays alerts that float on-top of the content.
 */
class Messages extends React.Component {

    static propType = {
        messages: PropTypes.array,
        onClose: PropTypes.func
    }

    render() {
        return (
            <div style={{position: "absolute", width: '100%', top: 0, left: 0, paddingTop: 5}}>
                <Flex justifyItems='center'>
                    <Flex.Item>
                        {this.props.messages.map((message, idx) => <div style={{zIndex: 10, position: "relative"}} key={idx}>
                                <Alert variant={message.variant} timeout={message.timeout} onDismiss={() => this.props.onClose(idx)} renderCloseButtonLabel="Close">
                                    {message.body}
                                </Alert>
                            </div>
                        )}
                    </Flex.Item></Flex>
            </div>
        )
    }
}

export default Messages