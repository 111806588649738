export function fetchWithAuth(url, options = {headers: {}}, token) {
    const headers = {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
        ...options.headers
    }
    return fetch(url, {...options, headers})
}

export const objectToItem = (object) => {
    return {
        id: object.key,
        name: this.toFilename(object.httpMetadata.contentDisposition),
        descriptor: `Size: ${object.size}, Uploaded: ${object.uploaded}`
    }
}

export const isDefined = (variable) => {
    const type = typeof variable
    return (type !== 'null' && type !== 'undefined')
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes, si=false, dp=1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
}

/**
 * Displays how long ago a file was uploaded.
 * This waits until you have 2 of the next unit before switching so you never see the singular.
 * @param date The date
 * @returns {string} a human string
 */
export function timeSince(date) {

    const seconds = Math.floor((new Date() - date) / 1000);
    let interval = Math.floor(seconds / 31536000);

    if (interval > 1) {
        return interval + " years ago";
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
        return interval + " months ago";
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
        return interval + " days ago";
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
        return interval + " hours ago";
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
        return interval + " minutes ago";
    }
    if (seconds > 10) {
        return Math.floor(seconds) + " seconds ago";
    }
    return "just now";
}

/**
 * A duration in human time
 * @param date The duration in milliseconds
 * @returns {string} a human string
 */
export function humanDuration(millis) {

    const seconds = Math.floor(millis / 1000);
    let interval = Math.floor(seconds / 31536000);

    if (interval > 1) {
        return interval + " years";
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
        return interval + " months";
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
        return interval + " days";
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
        return interval + " hours";
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
        return interval + " minutes";
    }
    if (seconds > 10) {
        return Math.floor(seconds) + " seconds";
    }
    return "now";
}

export function checkOk(response) {
    if (response.ok) {
        return response
    }
    if (response.status === 401) {
        throw new PermissionError(response.message)
    }
    throw new RemoteError(`Error, status: ${response.status}, message: ${response.message}`)
}

export function toMessage(reason, prefix) {
    if (reason instanceof PermissionError) {
        return {
            body: "Permission denied, has your session expired? Try relaunching the tool.",
            variant: "error"
        }
    }
    if (reason instanceof RemoteError) {
        return {
            body: `${prefix} Bad response from server. ${reason.message}`,
            variant: "error"
        }
    }
    return {
        body: `${prefix} Error: ${reason.message}`,
        variant: "error"
    }
}

export class PermissionError extends Error {
    constructor(msg) {
        super(msg);
    }
}

/**
 * An error went wrong at the remote end.
 */
export class RemoteError extends Error {
    constructor(msg) {
        super(msg);
    }
}
