import React from 'react'

import jwtDecode from "jwt-decode";
import {View} from "@instructure/ui-view";
import Messages from "./Messages";
import {Button, IconButton} from "@instructure/ui-buttons";
import {fetchWithAuth, humanDuration, timeSince} from "./utils";
import DeepLinkReturn from "./DeepLinkReturn";
import {Spinner} from "@instructure/ui-spinner";
import {TextInput} from "@instructure/ui-text-input";
import {Text} from "@instructure/ui-text";
import {Flex} from "@instructure/ui-flex";
import {Tooltip} from "@instructure/ui-tooltip";
import {Menu} from "@instructure/ui-menu";
import {IconInfoLine, IconMoreLine} from "@instructure/ui-icons";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import {
    Autoformat,
    BlockQuote,
    Bold,
    ClassicEditor,
    Essentials,
    FullPage,
    GeneralHtmlSupport,
    Heading,
    Italic,
    Link,
    List,
    Paragraph,
    SourceEditing
} from "ckeditor5";

import 'ckeditor5/ckeditor5.css';
import LtiApplyTheme from "./LtiApplyTheme.jsx";

const TEMPLATE = `<html lang="en">
  <head>
    <title>Page Title</title>
  </head>
  <body>
    <p>Sample content</p>
  </body>
</html>`

class App extends React.Component {
    state = {
        token: null,
        placement: null,
        brandConfig: null,
        highContrast: null,
        title: "",
        value: TEMPLATE,
        includeStyles: true,
        // Should we leave scroll bars on the content?
        autoResize: false,
        messages: [],
        loading: true,
        adding: true,
        returnUrl: null,
        // So we can display a spinner for adding/updating
        isBusy: false,
        readonly: false,
        // If we are currently editing the source
        sourceMode: false
    }

    editor = null
    _jwt = null

    componentDidMount() {
        // if null or undefined don't do this.
        if (typeof demoStart !== 'undefined' && demoStart !== null
            && typeof demoDuration !== 'undefined' && demoDuration !== null) {
            const demoEnd = demoStart + demoDuration
            const now = Date.now()
            if (demoEnd < now) {
                this.addMessage({variant: 'warning', body: `Evaluation Expired. Editing Locked`})
                this.setState({readonly: true})
            } else {
                const remaining = demoEnd - now
                this.addMessage({
                    variant: 'info',
                    body: `Evaluation Mode. Editing locks in ${humanDuration(remaining)}`
                })
            }
        }
        // This is the JWT from the LTI launch.
        if (typeof JWT !== 'undefined') {
            this.updateData(JWT)
        } else {
            this.addMessage({variant: 'error', body: 'You must launch this tool from Canvas'})
            this.setState({loading: false})
        }
    }


    /**
     * Load the current content.
     * @returns {Promise<void>}
     */
    loadContent = async () => {
        const response = await fetchWithAuth("/api/get", {}, JWT)
        if (!response.ok) {
            this.addMessage({
                variant: 'error',
                body: `Failed to load existing content. Please re-try. Error code: ${response.status}`
            })
            return
        }
        const value = await response.json()
        this.setState({
            value: value.body,
            embedded: value.embedded,
            includeStyles: value.includeStyles,
            autoResize: value.autoResize,
            updatedAt: value.updatedAt,
            updatedBy: value.updatedBy
        })
    }

    updateData = async (token) => {
        this._jwt = jwtDecode(token)
        let returnUrl
        if (this._jwt["https://purl.imsglobal.org/spec/lti/claim/message_type"] === 'LtiDeepLinkingRequest') {
            returnUrl = this._jwt["https://purl.imsglobal.org/spec/lti-dl/claim/deep_linking_settings"]["deep_link_return_url"]
        }
        const isInstructor =
            this._jwt['https://purl.imsglobal.org/spec/lti/claim/roles'].includes('http://purl.imsglobal.org/vocab/lis/v2/membership#Instructor') ||
            this._jwt['https://purl.imsglobal.org/spec/lti/claim/roles'].includes('http://purl.imsglobal.org/vocab/lis/v2/institution/person#Administrator')
        const user = this._jwt['sub']
        let title = ""
        if (this._jwt["https://purl.imsglobal.org/spec/lti/claim/message_type"] === 'LtiResourceLinkRequest') {
            // Stash the current title of the resource link.
            // This is so that it's clearer which module item we are editing.
            title = this._jwt['https://purl.imsglobal.org/spec/lti/claim/resource_link'].title
        }
        const placement = this._jwt['https://www.instructure.com/placement']
        const embedded = placement === 'editor_button'
        this.setState({
            token,
            placement,
            isInstructor,
            user,
            returnUrl,
            title,
            embedded,
            // Enable autoresize when we're embedded into some other content.
            autoResize: embedded,
            brandConfig: this._jwt['https://purl.imsglobal.org/spec/lti/claim/custom'].com_instructure_brand_config_json_url,
            highContrast: this._jwt['https://purl.imsglobal.org/spec/lti/claim/custom'].canvas_user_prefers_high_contrast === "true",
        })
        if (!returnUrl) {
            // We're editing an existing one here.
            // We can't do this load on LTI launch as we don't have the token at that point in time :-(
            this.setState({
                loading: true,
                adding: false
            })
            this.loadContent().finally(() => this.setState({loading: false}))
        } else {
            // This is needed so that content isn't shown by default and only once we're setup the state do
            // we show the content (this is because we have unmanaged inputs).
            this.setState({loading: false})
        }
    }

    handleRef = (ref) => {
        this.form = ref
    }

    onClose = idx => {
        this.setState((prev) => ({
            messages: prev.messages.filter((_, i) => i !== idx)
        }))
    }

    addMessage = (message) => {
        this.setState((prev) => ({
            messages: [...prev.messages, message]
        }))
    }

    setValue = (value) => {
        this.setState({
            value
        })
        // Warn before leaving once we've modified the content
        addEventListener("beforeunload", this.beforeUnloadListener, {capture: true});
    }

    setStyles = (e) => {
        this.setState({includeStyles: e.target.checked})
    }

    setAutoResize = (e) => {
        this.setState({autoResize: e.target.checked})
    }

    beforeUnloadListener = (event) => {
        // This shouldn't be necessary, but otherwise when calling window.close we end up in here twice :-(
        removeEventListener("beforeunload", this.beforeUnloadListener, {capture: true});
        event.preventDefault();
        return (event.returnValue = "");
    }

    onAdd = async () => {
        removeEventListener("beforeunload", this.beforeUnloadListener, {capture: true});
        if (!this.state.returnUrl) {
            this.addMessage({variant: 'error', body: `No return URL. Was tool correctly launched?`})
            return
        }
        this.setState({isBusy: true})
        try {
            const form = new FormData();
            form.set("title", this.state.title)
            form.set("html", this.state.value)
            form.set("includeStyles", this.state.includeStyles)
            form.set("autoResize", this.state.autoResize)
            form.set("embedded", this.state.embedded)
            const response = await fetchWithAuth("/api/deep-link", {
                body: form,
                method: 'POST'
            }, this.state.token)
            if (!response.ok) {
                this.addMessage({variant: 'error', body: `Failed to sign token. Error code: ${response.status}`})
                return
            }
            const json = await response.json()
            this.setState({
                jwt: json.JWT
            }, () => this.form.submit())
        } finally {
            this.setState({isBusy: false})
        }
    }

    onUpdate = async () => {
        removeEventListener("beforeunload", this.beforeUnloadListener, {capture: true});
        this.setState({isBusy: true})
        try {
            const form = new FormData();
            form.set("html", this.state.value)
            form.set("includeStyles", this.state.includeStyles)
            form.set('autoResize', this.state.autoResize)
            // We need the JWT with the ID set here.
            const response = await fetchWithAuth("/api/update", {
                body: form,
                method: 'POST'
            }, JWT)
            if (!response.ok) {
                this.addMessage({variant: 'error', body: `Failed to update. Error code: ${response.status}`})
                return
            }
            let reloaded = false
            // Try to find our own iframe and reload it.
            for (let i = 0; i < window.opener.frames.length; i++) {
                try {
                    window.opener.frames[i].location.reload()
                    reloaded = true
                    window.opener.postMessage(
                        {
                            subject: 'lti.showAlert',
                            alertType: 'success',
                            body: `'${this.state.title}' saved.`,
                            title: 'Content Plus',
                        },
                        '*'
                    )
                    break;
                } catch {
                    // Ignore: there doesn't seem to be a way to detect if it will work.
                }
            }
            if (!reloaded) {
                // Fallback message if we couldn't find our page.
                // This can happen when the page showing the content has been navigated somewhere else.
                window.opener.postMessage(
                    {
                        subject: 'lti.showAlert',
                        alertType: 'warning',
                        body: `'${this.state.title}' saved. Unable to reload the page.`,
                        title: 'Content Plus',
                    },
                    '*'
                )
            }
            window.close()
        } finally {
            this.setState({isBusy: false})
        }

    }

    onCancelUpdate = (e) => {
        // Editing is done in a new window so just close the window.
        window.close()
    }

    onCancelAdd = (e) => {
        // To not add content we just go to the return URL
        window.location = this._jwt['https://purl.imsglobal.org/spec/lti/claim/launch_presentation']['return_url']
    }

    renderSave = () => {
        const disabled = this.state.readonly || this.state.isBusy || this.state.sourceMode;
        return <>
            {this.state.sourceMode ? this.renderTitleTip("Switch out of source mode to save"): ""}
            {(this.state.adding) ?
                <Button color='primary' disabled={disabled} onClick={this.onAdd}>Add</Button> :
                <Button color='primary' disabled={disabled} onClick={this.onUpdate}>Update</Button>
            }
        </>
    }

    renderCancel = () => {
        return this.state.adding ?
            // When launched from the add module content (link_selection) you can't cancel it complains about
            // not finding any content, it works fine from module_menu.
            <Button interaction={this.state.placement === 'link_selection' ? 'disabled' : 'enabled'}
                    onClick={this.onCancelAdd}>Cancel</Button> :
            <Button onClick={this.onCancelUpdate}>Cancel</Button>

    }

    renderTitleTip(tip) {
        return <Tooltip
            renderTip={tip}
            placement="start"
            on={['click', 'hover', 'focus']}
        >
            <IconButton
                shape='circle'
                size='small'
                renderIcon={IconInfoLine}
                withBackground={false}
                withBorder={false}
                screenReaderLabel="Information"
            />
        </Tooltip>
    }
    renderTitle() {
        if (!this.state.embedded) {
            return <TextInput renderLabel={<>Title {this.state.adding || this.renderTitleTip("Title must be edited in Canvas.")} </>}
                              defaultValue={this.state.title}
                              interaction={this.state.adding ? 'enabled' : 'disabled'}
                              onChange={(_, title) => this.setState({title})}/>
        }
    }

    render() {
        const {adding, highContrast, brandConfig, isBusy} = this.state;
        return (
            <LtiApplyTheme url={brandConfig} highContrast={highContrast}>
                <View as='div' padding='small medium' height='100%'>
                    <Messages messages={this.state.messages} onClose={this.onClose}/>
                    {this.state.loading ? <Spinner renderTitle='Loading content'/> :
                        <Flex direction='column' height='100%' gap='xx-small'>
                            <Flex.Item padding='x-small'>
                                {this.renderTitle()}
                            </Flex.Item>
                            <Flex.Item shouldGrow shouldShrink padding='x-small'>
                                <CKEditor
                                    id='editor'
                                    editor={ClassicEditor}
                                    data={this.state.value}
                                    config={{
                                        plugins: [Essentials,
                                            Autoformat,
                                            Bold,
                                            Italic,
                                            BlockQuote,
                                            Heading,
                                            Link,
                                            List,
                                            Paragraph,
                                            // All these are because we are rendering a whole page:
                                            SourceEditing, GeneralHtmlSupport, FullPage],
                                        toolbar: {
                                            items: [
                                                'heading',
                                                '|',
                                                'bold',
                                                'italic',
                                                '|',
                                                'link',
                                                'bulletedList',
                                                'numberedList',
                                                'blockQuote',
                                                '|',
                                                'undo',
                                                'redo',
                                                '|',
                                                'sourceEditing'
                                            ]
                                        },
                                        htmlSupport: {
                                            allow: [
                                                {
                                                    name: /.*/,
                                                    attributes: true,
                                                    classes: true,
                                                    styles: true
                                                }
                                            ]
                                        }
                                    }}
                                    onReady={editor => {
                                        const sourcePlugin = editor.plugins.get('SourceEditing')
                                        if (sourcePlugin) {
                                            // The content doesn't get updated when in source mode, so we don't allow
                                            // saves until the user leaves source mode.
                                            sourcePlugin.on('change:isSourceEditingMode', (e, name, mode) => this.setState({sourceMode: mode}))
                                            this.setState({sourceMode: sourcePlugin.isSourceEditingMode})
                                        }
                                        console.log('Editor is ready to use!', editor);
                                    }}
                                    onChange={(event, editor) => {
                                        // console.log(editor.getData())
                                        this.setState({value: editor.getData()})
                                    }}
                                    onBlur={(event, editor) => {
                                        console.log('Blur.', editor);
                                    }}
                                    onFocus={(event, editor) => {
                                        console.log('Focus.', editor);
                                    }}
                                />
                                {/*<SourceCodeEditor label='content' defaultValue={this.state.value}*/}
                                {/*                  onChange={this.setValue} lineWrapping lineNumbers*/}
                                {/*                  foldGutter*/}
                                {/*                  highlightActiveLine highlightActiveLineGutter*/}
                                {/*                  language='html'*/}
                                {/*                  ref={(component) => {*/}
                                {/*                      this.editor = component*/}
                                {/*                  }}*/}
                                {/*                  height='100%'*/}
                                {/*/>*/}
                            </Flex.Item>
                            <Flex.Item padding='x-small'>
                                <Flex gap='small'>
                                    <Flex.Item>
                                        <Menu placement='top start' trigger={
                                            <Button renderIcon={<IconMoreLine/>}>Options</Button>
                                        }>
                                            <Menu.Item
                                                selected={this.state.includeStyles}
                                                type='checkbox'
                                                onSelect={(_e, _value, selected) => this.setState({includeStyles: selected})}
                                            >
                                                Include Canvas Styles
                                            </Menu.Item>
                                            <Menu.Item
                                                selected={this.state.autoResize}
                                                type='checkbox'
                                                onSelect={(_e, _value, selected) => this.setState({autoResize: selected})}
                                            >
                                                Auto Resize
                                            </Menu.Item>
                                        </Menu>
                                    </Flex.Item>
                                    <Flex.Item>
                                        {/*<Button onClick={() => {*/}
                                        {/*    this.editor.indentAll()*/}
                                        {/*}}>*/}
                                        {/*    Re-indent*/}
                                        {/*</Button>*/}
                                    </Flex.Item>
                                    <Flex.Item>
                                        {adding || <Text size='small'>
                                            Updated {this.relativeDateTime(this.state.updatedAt)} by {this.state.updatedBy}</Text>}
                                    </Flex.Item>
                                    <Flex.Item shouldGrow>
                                    </Flex.Item>
                                    {isBusy &&
                                        <Flex.Item>
                                            <Spinner size='x-small' renderTitle='Busy'/>
                                        </Flex.Item>
                                    }
                                    <Flex.Item>
                                        {this.renderSave()}
                                    </Flex.Item>
                                    <Flex.Item>
                                        {this.renderCancel()}
                                    </Flex.Item>
                                </Flex>
                            </Flex.Item>
                        </Flex>
                    }
                    <DeepLinkReturn returnUrl={this.state.returnUrl} elementRef={ref => this.form = ref}
                                    jwt={this.state.jwt}/>
                </View>
            </LtiApplyTheme>
        )
    }

    relativeDateTime(dateTime) {
        const date = new Date(Date.parse(dateTime))
        return <Tooltip renderTip={date.toLocaleString()}>
            {timeSince(date)}
        </Tooltip>
    }
}

export default App
