import React from 'react'
import PropTypes from "prop-types";

/**
 * Form to submit LTI Deep Linking response back to the Canvas.
 */
class DeepLinkReturn extends React.Component {

    static propTypes = {
        returnUrl: PropTypes.string,
        elementRef: PropTypes.func,
        jwt: PropTypes.string
    }
    
    static defaultProps = {
        jwt: '',
        returnUrl: ''
    }


    render() {
        return (
            <form action={this.props.returnUrl} method='POST' ref={this.props.elementRef}>
                <input type='hidden' name='JWT' value={this.props.jwt}/>
            </form>
        )
    }
}

export default DeepLinkReturn